<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-general-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte General</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_inicial"
                        :class="
                          $v.form.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_final"
                        :class="
                          $v.form.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="n_guia" class="col-md-5">N° Guía</label>
                    <input
                      type="number"
                      id="n_guia"
                      v-model="filtros.n_guia"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="campo" class="col-md-5">Campo</label>
                    <input
                      type="number"
                      id="campo"
                      v-model="filtros.campo"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="producto_liquido_id" class="col-md-5"
                      >Tipo Producto</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.producto_liquido_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="producto_liquido_id in listasForms.productos"
                        :key="producto_liquido_id.id"
                        :value="producto_liquido_id.id"
                      >
                        {{ producto_liquido_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="sitio_destino_id" class="col-md-5"
                      >Lugar de Destino</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.sitio_destino_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="sitio_destino_id in listasForms.sitiosDestinos"
                        :key="sitio_destino_id.id"
                        :value="sitio_destino_id.id"
                      >
                        {{ sitio_destino_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="barcaza_id" class="col-md-5">Barcaza</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.barcaza_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="barcaza_id in listasForms.barcazas"
                        :key="barcaza_id.id"
                        :value="barcaza_id.id"
                      >
                        {{ barcaza_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5"
                      >Quincena</label
                    >
                    <input
                        type="date"
                        class="form-control form-control-sm col-md-7"
                        v-model="filtros.quincena"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.form.$invalid"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can(
                    'hidrocarburos.transporte.fluvial.reportes.exportGeneral'
                  )
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteGeneralExport",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      guia: [],
      sitio_destino: {},
      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      filtros: {
        n_guia: null,
        campo: null,
        producto_liquido_id: null,
        sitio_destino_id: null,
        barcaza_id: null,
        quincena: "",
      },
      listasForms: {
        productos: [],
        sitiosDestinos: [],
        barcazas: [],
      },
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 30) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 30 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudesFluviales/exportGeneral",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          if (!response.data.error) {
            location.href = data.url;
            this.$swal({
              icon: "success",
              title: response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    limpiarForm() {
      this.form = {};
      this.filtros = {};
    },
  },

  mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
